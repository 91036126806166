

<template>
  <v-overlay :value="overlay">
    <v-btn
      icon
      @click="overlay = false"
    >

      <v-progress-circular
        indeterminate
        :color="color"
      ></v-progress-circular>

    </v-btn>
  </v-overlay>
</template>
<script>
  export default {
    name: 'appOverlay',
    props: ['overlay', 'color']
  }
</script>
